/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faLinkedin,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import exu from '../Images/exu.png';

function Footer() {
  // Check if the device is small (width <= 650px)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 650);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 650);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <footer style={{backgroundColor: 'black', color: 'white'}}>
      {isMobile ? (
        // For screens less than 650 pixels wide (mobile) it is just the logo and social media icons
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              flex: '1',
              textAlign: 'left',
            }}>
            <a href="/" style={{textDecoration: 'none', color: 'white'}}>
              <img
                src={exu}
                alt="Exu Logo"
                style={{maxWidth: '80%', height: 'auto', width: 'auto', maxHeight: '10vh'}}
              />
            </a>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '5vw',
            }}
          >
            <a
              href="https://www.linkedin.com/company/experience-u/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faLinkedin}
                style={{color: 'white', fontSize: '6vw', marginRight: '1vw'}}
              />
            </a>
            <a
              href="https://www.instagram.com/experienceutours"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                style={{color: 'white', fontSize: '6vw', marginRight: '1vw'}}
              />
            </a>
            <a
              href="https://twitter.com/experienceutour"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faTwitter}
                style={{color: 'white', fontSize: '6vw'}}
              />
            </a>
          </div>
        </div>
      ) : (
        // For screens greater than or equal to 650 pixels wide
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          {/* Left side of footer */}
          <div>
            <a href="/" style={{textDecoration: 'none', color: 'white'}}>
              <img
                src={exu}
                alt="Exu Logo"
                style={{width: 'auto', height: '10vh'}}
              />
            </a>
            {/* Social media icons */}
            <div style={{display: 'flex', marginLeft: '7%'}}>
              <a
                href="https://www.linkedin.com/company/experience-u/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  size="2x"
                  style={{color: 'white', marginRight: '1vw'}}
                />
              </a>
              <a
                href="https://www.instagram.com/experienceutours"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="2x"
                  style={{color: 'white', marginRight: '1vw'}}
                />
              </a>
              <a
                href="https://twitter.com/experienceutour"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faTwitter}
                  size="2x"
                  style={{color: 'white'}}
                />
              </a>
            </div>
            {/* Rights reserved */}
            <div
              style={{marginTop: '10vh', textAlign: 'left', marginLeft: '7%'}}
            >
              <p style={{fontSize: '1vw', color: 'white'}}>
                &copy; 2023 All Rights Reserved
              </p>
            </div>
          </div>
          {/* Right side of footer */}
          <div
            style={{display: 'flex', marginRight: '10vw', textAlign: 'left'}}
          >
            {/* Company, Products, Policies */}
            <div style={{display: 'flex'}}>
              {/* Company */}
              <div style={{marginRight: '5vw'}}>
                <p
                  style={{
                    color: 'lightgreen',
                    fontSize: '3vh',
                    marginBottom: '2vh',
                  }}
                >
                  Company
                </p>
                <div style={{marginBottom: '10px'}}>
                  <a
                    href="/survey"
                    style={{textDecoration: 'none', color: 'white'}}
                  >
                    Survey
                  </a>
                </div>
                <div style={{marginBottom: '10px'}}>
                  <span style={{color: 'white'}}>Contact</span>
                </div>
                <div style={{marginBottom: '10px'}}>
                  <span style={{color: 'white'}}>About Us</span>
                </div>
              </div>
              {/* Products */}
              <div style={{marginTop: '0vh', marginRight: '5vw'}}>
                <p
                  style={{
                    color: 'lightgreen',
                    fontSize: '3vh',
                    marginBottom: '2vh',
                  }}
                >
                  Products
                </p>
                <div style={{marginBottom: '10px'}}>
                  <span style={{color: 'white'}}>Mobile App</span>
                </div>
                <div style={{marginBottom: '10px'}}>
                  <span style={{color: 'white'}}>Virtual Reality</span>
                </div>
              </div>
              {/* Policies */}
              <div style={{marginTop: '0vh'}}>
                <p
                  style={{
                    color: 'lightgreen',
                    fontSize: '3vh',
                    marginBottom: '2vh',
                  }}
                >
                  Policies
                </p>
                <div style={{marginBottom: '10px'}}>
                  <span style={{color: 'white'}}>Privacy</span>
                </div>
                <div style={{marginBottom: '10px'}}>
                  <span style={{color: 'white'}}>Terms & Conditions</span>
                </div>
                <div style={{marginBottom: '10px'}}>
                  <span style={{color: 'white'}}>Cookie Settings</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </footer>
  );
}

export default Footer;
