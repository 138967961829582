/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React, {useState, useEffect} from 'react';
import background from '../Images/background.png';
import download from '../Images/download.png';
import interests from '../Images/interests.png';
import route from '../Images/route.png';
import explore from '../Images/explore.png';

const Home = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showPopup, setShowPopup] = useState(false);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Add event listener to window to track window width
  useEffect(() => {
    console.log(process.env);
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // Check if the device is small (width <= 1200px)
  const isSmallDevice = windowWidth <= 1200;

  // style for the main home page (blue part)
  const containerStyle = {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: isSmallDevice ? '60vw' : '100vh',
    display: 'flex',
    justifyContent: 'center',
  };

  // style for the text and image in the main home page
  const textContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: isSmallDevice ? '5vh' : '25vh',
    position: 'relative',
    marginTop: isSmallDevice ? '10%' : '17%',
  };

  // style for the heading in the main home page
  const headingStyle = {
    color: 'white',
    fontSize: isSmallDevice ? '6vw' : '3.5vw',
    marginBottom: '0vw',
    marginTop: isSmallDevice ? '-5vw' : '-9vw',
  };

  // style for the text in the main home page
  const textStyle = {
    color: 'white',
    fontSize: isSmallDevice ? '3vw' : '1.5vw',
  };

  // style for download button
  const imageStyle = {
    width: isSmallDevice ? '40vw' : '20vw',
    position: 'absolute',
    marginTop: isSmallDevice ? '20.5vw' : '25%',
    left: '50%',
    transform: 'translate(-50%, 0)',
  };

  // style for pop up when download button is clicked
  const popupStyle = {
    position: 'absolute',
    top: isSmallDevice ? '30%' : '70%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    padding: '1%',
    borderRadius: '8px',
    textAlign: 'center',
    opacity: showPopup ? 1 : 0,
    transition: 'opacity 0.3s ease-in-out',
    width: isSmallDevice ? '50%' : '10%',
    height: isSmallDevice ? '2.25%' : '5%',
    fontSize: isSmallDevice ? '3vw' : '1vw',
  };

  // Toggle the popup
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  // Handle the download button click
  const handleImageClick = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    togglePopup();

    // Automatically hide the popup after 3 seconds (3000 milliseconds)
    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
  };

  // Define styles for the interests section
  const interestsSectionStyle = {
    display: 'flex',
    flexDirection: isSmallDevice ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: isSmallDevice ? 'center' : 'flex-start',
    margin: isSmallDevice ? '5% 0' : '5% 10%',
    padding: isSmallDevice ? '5%' : '0',
    backgroundColor: 'white',
  };

  const interestsImageStyle = {
    width: isSmallDevice ? '60vw' : '120vw',
    marginRight: isSmallDevice ? '0' : '5vw',
  };

  const interestsTextStyle = {
    color: 'black',
    fontSize: isSmallDevice ? '3.5vw' : '1.5vw',
    textAlign: isSmallDevice ? 'center' : 'left',
    lineHeight: 1.5,
    marginTop: isSmallDevice ? '-1vh' : '-4vh',
  };

  // Define styles for the routes section
  const routesSectionStyle = {
    backgroundColor: '#ccc',
    display: 'flex',
    flexDirection: isSmallDevice ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: isSmallDevice ? '5%' : '10%',
  };

  const routesImageStyle = {
    width: isSmallDevice ? '50vw' : '30vw',
    marginLeft: isSmallDevice ? '0' : '5vw',
  };

  const routesTextContainerStyle = {
    flex: 1,
  };

  const routesHeaderStyle = {
    color: 'black',
    fontSize: isSmallDevice ? '5vw' : '3vw',
    textAlign: isSmallDevice ? 'center' : 'left',
    marginBottom: '1rem',
  };

  const routesParagraphStyle = {
    color: 'black',
    fontSize: isSmallDevice ? '3.5vw' : '1.5vw',
    textAlign: isSmallDevice ? 'center' : 'left',
    lineHeight: 1.5,
  };

  // Define styles for the survey section
  const helpSectionStyle = {
    backgroundColor: '#ccc',
    textAlign: 'center',
    padding: '5%',
  };

  const helpHeaderStyle = {
    color: 'black',
    fontWeight: 'bold',
    fontSize: isSmallDevice ? '4.5vw' : '3vw',
  };

  const helpParagraphStyle = {
    color: 'black',
    fontSize: isSmallDevice ? '3vw' : '1.5vw',
    marginTop: '2%',
  };

  const helpFooterStyle = {
    color: 'black',
    fontSize: isSmallDevice ? '3.5vw' : '2vw',
    marginTop: isSmallDevice ? '12%' : '7%',
  };

  const helpButtonStyle = {
    backgroundColor: '#007BFF',
    color: 'white',
    display: 'inline-block',
    padding: '10px 20px',
    fontSize: isSmallDevice ? '3vw' : '1.5vw',
    marginTop: '2%',
    textDecoration: 'none',
    borderRadius: '5px',
  };

  return (
    <div>
      {/* Main Home Page */}
      <div style={containerStyle}>
        <div style={textContainerStyle}>
          <h1 style={headingStyle}>College Discovery, Tailored To You!</h1>
          <p style={textStyle}>
            {isSmallDevice ? (
              <>
                Embark on a tailor-made adventure with ExperienceU - where every
                <br />
                step is crafted to make your campus tour truly your own.
              </>
            ) : (
              <>
                Embark on a tailor-made adventure with ExperienceU - where every
                step is
                <br />
                crafted to make your campus tour truly your own.
              </>
            )}
          </p>
          <a href="/download" onClick={handleImageClick}>
            <img src={download} alt="Download" style={imageStyle} />
          </a>
        </div>

        <div style={popupStyle}>No official app yet, coming soon</div>
      </div>

      {/* Interests Section */}
      <div style={interestsSectionStyle}>
        <img src={interests} alt="Interests" style={interestsImageStyle} />
        <div>
          <h2
            style={{
              color: 'black',
              fontWeight: 'bold',
              textAlign: isSmallDevice ? 'center' : 'left',
              fontSize: isSmallDevice ? '5vw' : '3vw',
            }}
          >
            Select Interests
          </h2>
          <p style={interestsTextStyle}>
            ExperienceU customizes your campus tour to match your passions, be
            it art, sports, or science. Our algorithm, designed with student
            input, tailors the experience to highlight what matters most to you.
          </p>
        </div>
      </div>

      {/* Routes Section */}
      <div style={routesSectionStyle}>
        {isSmallDevice ? (
          <>
            <img src={route} alt="Route" style={routesImageStyle} />
            <div style={routesTextContainerStyle}>
              <h2 style={routesHeaderStyle}>View Route</h2>
              <p style={routesParagraphStyle}>
                Based on the chosen interests and time duration, our proprietary
                algorithm calculates the optimal route. ExperienceU ensures that
                students don't miss out on any must-see locations, while also
                factoring in the time needed for exploration and breaks.
              </p>
            </div>
          </>
        ) : (
          <>
            <div style={routesTextContainerStyle}>
              <h2 style={routesHeaderStyle}>View Route</h2>
              <p style={routesParagraphStyle}>
                Based on the chosen interests and time duration, our proprietary
                algorithm calculates the optimal route. ExperienceU ensures that
                students don't miss out on any must-see locations, while also
                factoring in the time needed for exploration and breaks.
              </p>
            </div>
            <img src={route} alt="Route" style={routesImageStyle} />
          </>
        )}
      </div>

      {/* Explore Section */}
      <div style={interestsSectionStyle}>
        <img src={explore} alt="Explore" style={interestsImageStyle} />
        <div>
          <h2
            style={{
              color: 'black',
              fontWeight: 'bold',
              textAlign: isSmallDevice ? 'center' : 'left',
              fontSize: isSmallDevice ? '5vw' : '3vw',
            }}
          >
            Explore
          </h2>
          <p style={interestsTextStyle}>
            Discover hidden gems on campus and explore the unique features that
            make each university special. With ExperienceU, you'll uncover the
            beauty and charm of your potential future home.
          </p>
        </div>
      </div>

      {/* Help Section */}
      <div style={helpSectionStyle}>
        <h2 style={helpHeaderStyle}>
          Help our development with a short survey
        </h2>
        <p style={helpParagraphStyle}>
          Student collaboration is the backbone of ExperienceU’s personalized
          tour experience. By sharing your insights, preferences, and favorite
          discoveries around campus, you play an instrumental role in creating a
          dynamic database that tailors tour paths for future college students.{' '}
        </p>
        <p style={helpFooterStyle}>Ready to share your journey?</p>
        <a href="/survey" style={helpButtonStyle}>
          Take Our Survey
        </a>
      </div>
    </div>
  );
};

export default Home;
