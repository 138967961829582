/* eslint-disable react/no-unescaped-entities */
/* eslint-disable require-jsdoc */
import React, {useState, useEffect} from 'react';
import {
  Select,
  MenuItem,
  InputLabel,
  Button,
  Box,
  Container,
  Checkbox,
  Typography,
  TextField,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AWS from 'aws-sdk';
import background from '../Images/background.png';
import '../CSS/Survey.css';

export default function Survey() {
  // Global Variables needed to store landmarks/selected values
  const [formLandmarks, setFormLandmarks] = React.useState([]);

  // School Landmarks
  const [uvaLandmarks, setUVALandmark] = React.useState([]);
  const [vtLandmarks, setVTLandmark] = React.useState([]);

  // General Traits List
  const [traits, setTraits] = React.useState([]);
  const [traits2, setTraits2] = React.useState([]);

  // Majors List
  const [majors, setMajors] = React.useState([]);

  const condition = (item) => item['school']['S'] === 'UVA';
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  // Figuring out screen size
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Add event listener to window to track window width
  useEffect(() => {
    console.log(process.env);
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // Check if the device is small (width <= 1200px)
  const isSmallDevice = windowWidth <= 1200;

  // @Codemonkeys - If we want to deploy on AWS, then this line is unnecessary
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const dynamodbDocClient = new AWS.DynamoDB.DocumentClient();

  React.useEffect(() => {
    // Create a DynamoDB client
    const dynamodb = new AWS.DynamoDB();

    // Set the name of the table you want to scan
    const tableName = 'Landmarks';

    // Define the scan parameters
    const scanParams = {
      TableName: tableName,
    };

    const uva = [];
    const vt = [];

    // Perform the scan
    dynamodb.scan(scanParams, (err, data) => {
      if (err) {
        console.error('Error scanning the table:', err);
      } else {
        // data.Items => Landmarks from the Dynamo Table
        // Condition is if the landmark is "UVA"
        data.Items.forEach((item) => {
          // Grabs the first letter of each option
          const firstLetter = item.name['S'][0].toUpperCase();
          item = {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...item,
          };
          if (condition(item)) {
            uva.push(item);
          } else {
            vt.push(item);
          }
        });

        // Sorts the options based on the name value
        uva.sort((a, b) => (a.name['S'] > b.name['S'] ? 1 : -1));
        vt.sort((a, b) => (a.name['S'] > b.name['S'] ? 1 : -1));

        // Set State Variables
        setUVALandmark(uva);
        setVTLandmark(vt);
        setFormLandmarks(uva);
      }
    });

    const scanParamsTraits = {
      TableName: 'Traits',
    };

    // Perform the scan
    dynamodb.scan(scanParamsTraits, (err, data) => {
      if (err) {
        console.error('Error scanning the table:', err);
      } else {
        const responseItems = data.Items;
        const tempTraits = [];
        const tempTraits2 = [];
        const tempMajors = [];

        // Peforms a Dynamo Table Scan of Available Traits
        responseItems.forEach((item) => {
          if (item['category']['S'] === 'Majors') {
            const arr = item['traits']['L'];
            arr.forEach((element) => {
              // Calculates First Letters
              const firstLetter = element['S'][0].toUpperCase();
              element = {
                name: element['S'],
                firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
              };
              tempMajors.push(element);
            });
          } else if (
            item['category']['S'] === 'Hobbies' ||
            item['category']['S'] === 'Sports' ||
            item['category']['S'] === 'Adjectives'
          ) {
            const arr = item['traits']['L'];
            arr.forEach((element) => {
              // Calculates First Letters
              const firstLetter = element['S'][0].toUpperCase();
              element = {
                name: element['S'],
                firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
              };
              tempTraits2.push(element);
            });
          } else {
            const arr = item['traits']['L'];
            arr.forEach((element) => {
              // Calculates First Letters
              const firstLetter = element['S'][0].toUpperCase();
              element = {
                name: element['S'],
                firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
              };
              tempTraits.push(element);
            });
          }
        });

        // Sort tempTraits array
        tempTraits.sort((a, b) => (a.name > b.name ? 1 : -1));
        tempMajors.sort((a, b) => (a.name > b.name ? 1 : -1));
        setTraits(tempTraits);
        setTraits2(tempTraits2);
        setMajors(tempMajors);
      }
    });

    console.log(process.env);
    console.log(process.env.REACT_APP_PUBLIC_URL + '/logo.png');
  }, []);

  // Form-Bases State Variables
  const [school, setSchool] = React.useState('UVA');
  const [selectedTraits, setSelectedTraits] = React.useState([]);
  const [selectedTraits2, setSelectedTraits2] = React.useState([]);
  const [selectedLandmarks, setSelectedLandmarks] = React.useState([]);
  const [selectedMajors, setSelectedMajors] = React.useState([]);

  // Handles the change of school value
  const handleChangeSchool = (event) => {
    setSchool(event.target.value);
    if (event.target.value === 'VT') {
      setFormLandmarks(vtLandmarks);
    } else {
      setFormLandmarks(uvaLandmarks);
    }
    setSelectedLandmarks([]);
  };

  // Handles the change of trait value
  const handleChangeTrait = (event, value) => {
    setSelectedTraits(value);
  };

  const handleChangeTrait2 = (event, value) => {
    setSelectedTraits2(value);
  };

  // Handles the change of landmarks
  const handleChangeLandmark = (event, value) => {
    setSelectedLandmarks(value);
  };

  // Handles the change of majors selected
  const handleChangeMajor = (event, value) => {
    setSelectedMajors(value);
  };

  // Form Submission - Sending values to Dynamo table
  const handleFormSubmission = (event) => {
    event.preventDefault();

    // Parameters for DynamoDB put()
    const combinedTraits = selectedTraits.concat(selectedTraits2);
    const params = {
      TableName: 'Form-Responses',
      Item: {
        form_id:
          String(event.target.name_field.value) +
          ' ' +
          new Date().toUTCString(),
        name: String(event.target.name_field.value),
        major: selectedMajors,
        traits: combinedTraits,
        landmarks: selectedLandmarks,
        comments: String(event.target.comment_field.value),
        school: String(school),
      },
    };

    dynamodbDocClient.put(params, function(err, data) {
      if (err) throw err;
      else console.log(data);
    });

    alert('Form Submitted - Thank you!');

    // Reset Form
    setSelectedTraits([]);
    setSelectedTraits2([]);
    setSelectedLandmarks([]);
    setSelectedMajors([]);
    document.forms['experienceu_form'].reset();
  };

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '2.5%',
      }}
    >

      <Box
        sx={{
          flexGrow: 1,
          color: 'white',
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Container maxWidth="sm">
          <Box paddingTop="3%" paddingBottom="3%">
            {!isSmallDevice && (
              <Typography variant="h3" align="center" marginBottom="3%">
                Feedback Survey
              </Typography>
            )}
            <Box>
              This survey is designed to gain insights into your personality
              traits and interests, and to understand what your dream college
              tour would look like. Your sincere responses will contribute to
              creating the best version of ExperienceU.
            </Box>
          </Box>
          <InputLabel style={{color: 'white'}} id="school-dropdown-label">
            School
          </InputLabel>
          <Select
            id="school-dropdown"
            value={school}
            onChange={handleChangeSchool}
            style={{color: 'white'}}
          >
            <MenuItem value="UVA">University of Virginia</MenuItem>
            <MenuItem value="VT">Virginia Tech</MenuItem>
          </Select>

          <form id="experienceu_form" onSubmit={handleFormSubmission}>
            <Box paddingTop="5%" paddingBottom="3%">
              Last, First Name
              <TextField
                required
                id="name_field"
                variant="standard"
                InputProps={{
                  style: {color: 'white'},
                }}
                InputLabelProps={{
                  style: {color: 'white'},
                }}
                style={{minWidth: '100%'}}
              />
            </Box>

            <Box paddingTop="3%" paddingBottom="3%">
              <Box>Major(s)</Box>
              <Autocomplete
                multiple
                id="selected_majors_field"
                options={majors}
                value={selectedMajors}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.name}
                onChange={handleChangeMajor}
                disableCloseOnSelect
                renderOption={(props, option, {selected}) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                style={{minWidth: '25%'}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    className="white-textfield"
                  />
                )}
              />
            </Box>

            <Box paddingTop="3%" paddingBottom="3%">
              <Box>
                Choose as many organizations/communities you associate(d) with
                in your college experience
              </Box>
              <Autocomplete
                multiple
                id="selected_traits_field"
                options={traits}
                value={selectedTraits}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.name}
                onChange={handleChangeTrait}
                disableCloseOnSelect
                renderOption={(props, option, {selected}) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                style={{minWidth: '25%'}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    className="white-textfield"
                  />
                )}
              />
            </Box>
            <Box paddingTop="3%" paddingBottom="3%">
              <Box>
                Select as many hobbies, sports, and interests that best
                represent your college experience
              </Box>
              <Autocomplete
                multiple
                id="selected_traits_field_2"
                options={traits2.sort((a, b) => a.name.localeCompare(b.name))}
                value={selectedTraits2}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.name}
                onChange={handleChangeTrait2}
                disableCloseOnSelect
                renderOption={(props, option, {selected}) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                style={{minWidth: '25%'}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    className="white-textfield"
                  />
                )}
              />
            </Box>

            <Box paddingTop="3%" paddingBottom="3%">
              <Box>
                If you could go back in time and create a personalized tour for
                yourself, which buildings would you include?
              </Box>
              <Autocomplete
                multiple
                id="selected_landmarks_field"
                options={formLandmarks}
                onChange={handleChangeLandmark}
                value={selectedLandmarks}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.name['S']}
                disableCloseOnSelect
                renderOption={(props, option, {selected}) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.name['S']}
                  </li>
                )}
                style={{minWidth: '25%'}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    className="white-textfield"
                  />
                )}
              />
            </Box>

            <Box paddingTop="3%" paddingBottom="3%" minWidth={'100%'}>
              <Box>
                Comment in this box if any particular majors, traits, or
                buildings we're missing, or if there's anything else we should
                know
              </Box>
              <TextField
                id="comment_field"
                multiline
                maxRows={4}
                variant="standard"
                InputProps={{
                  style: {color: 'white'},
                  defaultValue: '',
                }}
                style={{minWidth: '100%'}}
              />
            </Box>
            <Box paddingTop="3%" paddingBottom="3%">
              <Button variant="contained" type="submit" id="submit-button">
                Submit
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </div>
  );
}
