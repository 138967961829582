/* eslint-disable require-jsdoc */
// React Imports
import React, {Component} from 'react';

// CSS Imports
import './App.css';

// Component Imports
import Template from './Components/Template';

// External Imports
import {Auth0Provider} from '@auth0/auth0-react';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: window.location.origin,
          }}
        >
          <Template></Template>
        </Auth0Provider>
      </div>
    );
  }
}

export default App;
