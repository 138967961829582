/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React, {useState, useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {Link} from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {useAuth0} from '@auth0/auth0-react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Popover from '@mui/material/Popover';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import navbar from '../Images/navbar.png';
import exu from '../Images/exu.png';

function NavigationBar() {
  const {loginWithRedirect, isAuthenticated, logout, user} = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [logoutOpen, setLogoutOpen] = useState(false); // State variable to track logout dropdown

  // Mobile menu open
  const handleMobileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Mobile menu close
  const handleMobileMenuClose = () => {
    setAnchorEl(null);
  };

  // Logout dropdown open
  const handleLogoutOpen = (event) => {
    setLogoutOpen(true);
    setAnchorEl(event.currentTarget); // Set anchor to ensure dropdown appears under the profile picture
  };

  // Logout dropdown close
  const handleLogoutClose = () => {
    setLogoutOpen(false);
  };

  // Add event listener to window to track window width
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    // Add event listener to window to track window width
    window.addEventListener('resize', handleResize);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Check if popup is open
  const open = Boolean(anchorEl);

  return (
    // Navigation bar
    <AppBar
      position="static"
      sx={{
        backgroundImage: `url(${navbar})`,
        backgroundSize: 'cover',
        boxShadow: 'none',
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {/* Logo */}
        <Link to="/" style={{textDecoration: 'none'}}>
          <img src={exu} alt="ExperienceU" style={{height: '60px'}} />
        </Link>
        {isMobile ? (
          <>
            {/* Mobile Menu Icon */}
            <IconButton
              sx={{
                display: isMobile ? 'block' : 'none',
                color: 'white',
              }}
              onClick={handleMobileMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            {/* Mobile Menu */}
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleMobileMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <div
                style={{
                  backgroundColor: 'none',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                  zIndex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  width: '150px',
                }}
              >
                {/* Display "Survey" only in the mobile menu */}
                {isMobile && (
                  <Tab
                    label="Survey"
                    component={Link}
                    to="/survey"
                    onClick={handleMobileMenuClose}
                    sx={{fontWeight: 'bold', padding: '10px', color: 'black'}}
                  />
                )}
                {/* Display "Log Out" button in the mobile menu */}
                {isAuthenticated && (
                  <Tab
                    label="Log Out"
                    onClick={() => {
                      logout({returnTo: window.location.origin});
                      handleMobileMenuClose();
                    }}
                    sx={{fontWeight: 'bold', padding: '10px', color: 'black'}}
                  />
                )}
                {/* Display "Login" in the mobile menu */}
                {!isAuthenticated && (
                  <Tab
                    label="Login"
                    onClick={() => {
                      loginWithRedirect();
                      handleMobileMenuClose();
                    }}
                    sx={{fontWeight: 'bold', padding: '10px', color: 'black'}}
                  />
                )}
              </div>
            </Popover>
          </>
        ) : (
          // Desktop view without dropdown for devices wider than 1200px
          <>
            {/* "Survey" Tab */}
            <Tabs
              value={false}
              aria-label="navigation tabs"
              sx={{
                display: 'block',
                marginLeft: 'auto',
              }}
            >
              <Tab
                label="Survey"
                component={Link}
                to="/survey"
                sx={{
                  textDecoration: 'none',
                  color: 'white',
                  fontWeight: 'bold',
                }}
              />
            </Tabs>
            {/* Profile dropdown */}
            {isAuthenticated && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  onClick={handleLogoutOpen}
                  sx={{
                    cursor: 'pointer',
                    color: 'black',
                  }}
                >
                  <img
                    src={user.picture}
                    alt={user.name}
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: '50%',
                    }}
                  />
                </IconButton>
                {/* Logout dropdown */}
                {!isMobile && isAuthenticated && logoutOpen && (
                  <Popover
                    open={logoutOpen}
                    anchorEl={anchorEl}
                    onClose={handleLogoutClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: 'none',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                        zIndex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        width: '150px',
                      }}
                    >
                      <Tab
                        label="Log Out"
                        onClick={() => {
                          logout({returnTo: window.location.origin});
                          handleLogoutClose();
                        }}
                        sx={{
                          fontWeight: 'bold',
                          padding: '10px',
                          color: 'black',
                        }}
                      />
                    </div>
                  </Popover>
                )}
              </div>
            )}
            {/* Conditionally render AccountCircleIcon */}
            {!isAuthenticated && (
              <IconButton
                onClick={loginWithRedirect}
                sx={{
                  cursor: 'pointer',
                  color: 'white',
                }}
              >
                <AccountCircleIcon fontSize="large" />
              </IconButton>
            )}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default NavigationBar;
