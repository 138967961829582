/* eslint-disable require-jsdoc */
// React Imports
import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

// Component Imports
import NavigationBar from './NavigationBar';

// Page Imports
import Footer from './Footer';
import Home from '../Pages/Home';
import Survey from '../Pages/Survey';


export default function Template() {
  return (
    <BrowserRouter>
      <NavigationBar />
      <div className="Main-Content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/survey" element={<Survey />} />
        </Routes>
      </div>
      <div className="Footer">
        <Footer />
      </div>
    </BrowserRouter>
  );
}
